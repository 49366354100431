const sendDesignChooseEvent = () => {
  if (typeof window === 'undefined') {
    return;
  }

  window.dataLayer.push({
    event: 'design_choose',
  });
};

export default sendDesignChooseEvent;
