import React from 'react';
import _map from 'lodash/map';

import config from 'config';

import { FormattedMessage, Link } from 'gatsby-plugin-intl';

import getGroupedPanels from 'utils/getGroupedPanels';
import sendDesignChooseEvent from 'utils/sendDesignChooseEvent';

import './ConfiguratorPageStep1.scss';

const ConfiguratorPageStep1 = () => (
  <div className="configurator-page-step-1">
    <div className="two-columns above-tablet-inline">
      <div className="left">
        <div className="line" />
        <h1>
          <FormattedMessage id="configuratorStep1Configurator" />
        </h1>
        <div className="choose-type">
          <FormattedMessage id="configuratorStep1ChooseType" />
        </div>
        <div className="panel-type-selector">
          {
              _map(getGroupedPanels(config.panelTypes), (el, key) => (
                <div key={key} className="group-wrapper">
                  <div className="group-name">
                    {key}
                  </div>
                  <div className="items">
                    {
                      _map(el, (el1) => (
                        <div key={el1.id} className="panel-type-image">
                          <Link
                            to="/configurator/step-2"
                            state={{ typeId: el1.id }}
                            onClick={() => {
                              sendDesignChooseEvent();
                            }}
                          >
                            <img src={`/images/${el1.image}`} alt="" className="img-responsive" />
                          </Link>
                        </div>
                      ))
                    }
                  </div>
                </div>
              ))
          }
        </div>
      </div>
      <div className="right">
        <img src="/images/configurator-sample.jpg" alt="" className="img-responsive" />
      </div>
    </div>
  </div>
);

export default ConfiguratorPageStep1;
